import {
  Token,
  useERC20TokenContract,
  Select,
  Input,
} from '@byont-ventures/react-dapp-framework';
import { faPercentage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useCallback, useEffect, useState } from 'react';
import { StrategyType } from '../../context/InvestmentContext';
import type { Warden } from '../../generated/Warden';
import { Decimal } from 'decimal.js';

export const StrategyMap = {
  [StrategyType.TrailingStopLoss]: 0,
  [StrategyType.TakeProfit]: 1,
  [StrategyType.LimitOrder]: 2,
};

export const StrategyForm: React.FunctionComponent<{
  tokenA: string;
  tokenB: string;
  onChange?: (data: Warden.StrategyInputDataStruct & { token: string }) => void;
  strategyType?: StrategyType;
  tokenAmount?: string;
  trailingPercentage?: string;
  executionRate?: string;
  editable?: boolean;
}> = ({
  tokenA,
  tokenB,
  strategyType = StrategyType.TrailingStopLoss,
  tokenAmount = '0',
  trailingPercentage = '0',
  executionRate = '0',
  editable = true,
  onChange,
}) => {
  const [selectedStrategyType, setSelectedStrategyType] =
    useState(strategyType);

  const tokenAContract = useERC20TokenContract(tokenA);
  const tokenBContract = useERC20TokenContract(tokenB);

  const [tokenADecimals, setTokenADecimals] = useState(18);
  const [tokenBDecimals, setTokenBDecimals] = useState(18);

  const [tokenAmountVal, setTokenAmountVal] = useState(
    tokenAmount === '' ? '0' : tokenAmount
  );
  const [trailingPercentageVal, setTrailingPercentageVal] =
    useState(trailingPercentage);
  const [executionRateVal, setExecutionRateVal] = useState(executionRate);

  const getDecimals = useCallback(async () => {
    try {
      setTokenADecimals(await tokenAContract.decimals());
      setTokenBDecimals(await tokenBContract.decimals());
    } catch (ex) {
      // todo
    }
  }, [tokenAContract, tokenBContract]);

  const emitChange = () =>
    onChange
      ? onChange({
          startingRate: '0',
          strategyType: StrategyMap[selectedStrategyType],
          tokenAmount: tokenAmountVal,
          percentage: trailingPercentageVal,
          executionRate: executionRateVal,
          token: [StrategyType.LimitOrder].includes(selectedStrategyType)
            ? tokenA
            : tokenB,
        })
      : null;

  useEffect(() => {
    emitChange();
    // TODO: FIx this warning
  }, [
    selectedStrategyType,
    tokenA,
    tokenB,
    executionRateVal,
    tokenAmountVal,
    trailingPercentageVal,
  ]);

  useEffect(() => {
    getDecimals();
  }, [getDecimals, tokenA, tokenB]);

  return (
    <div className="flex flex-col space-y-4">
      <div className="space-y-2">
        <label className="font-medium" htmlFor="">
          Strategy
        </label>
        <Select
          disabled={!editable}
          onChange={(value) => setSelectedStrategyType(value as StrategyType)}
          value={selectedStrategyType}
          values={[
            StrategyType.TrailingStopLoss,
            StrategyType.TakeProfit,
            StrategyType.LimitOrder,
          ]}
        />
      </div>

      {[StrategyType.TrailingStopLoss, StrategyType.TakeProfit].includes(
        selectedStrategyType
      ) ? (
        <div className="space-y-2">
          <label className="font-medium" htmlFor="">
            Sell Amount
          </label>
          <Input
            placeholder="2"
            type="number"
            step="0.1"
            disabled={!editable}
            value={new Decimal(
              formatUnits(tokenAmountVal, tokenADecimals)
            ).toString()}
            onChange={(e) => {
              try {
                setTokenAmountVal(
                  parseUnits(e.target.value, tokenADecimals).toString()
                );
                emitChange();
              } catch (ex) {
                //
              }
            }}
          >
            <Token address={tokenA} />
          </Input>
        </div>
      ) : null}

      {[StrategyType.LimitOrder].includes(selectedStrategyType) ? (
        <>
          <div className="space-y-2">
            <label className="font-medium" htmlFor="">
              Sell Amount
            </label>
            <Input
              placeholder="1.1"
              type="number"
              step="0.1"
              disabled={!editable}
              value={new Decimal(
                formatUnits(tokenAmountVal, tokenADecimals)
              ).toString()}
              onChange={(e) => {
                try {
                  setTokenAmountVal(
                    parseUnits(e.target.value, tokenADecimals).toString()
                  );
                  emitChange();
                } catch (ex) {
                  //
                }
              }}
            >
              <Token address={tokenA} />
            </Input>
          </div>
          <div className="space-y-2">
            <label className="font-medium" htmlFor="">
              Minimum Out
            </label>
            <Input
              placeholder="2"
              type="number"
              step="0.1"
              disabled={!editable}
              value={new Decimal(
                formatUnits(executionRateVal, tokenBDecimals)
              ).toString()}
              onChange={(e) => {
                try {
                  setExecutionRateVal(
                    parseUnits(e.target.value, tokenBDecimals).toString()
                  );
                  emitChange();
                } catch (ex) {
                  //
                }
              }}
            >
              <Token address={tokenB} />
            </Input>
          </div>
        </>
      ) : null}

      {[StrategyType.TrailingStopLoss].includes(selectedStrategyType) ? (
        <div className="space-y-2">
          <label className="font-medium" htmlFor="">
            Trailing percentage
          </label>
          <Input
            placeholder="2"
            type="number"
            disabled={!editable}
            value={new Decimal(trailingPercentageVal).div(100).toString()}
            step="0.1"
            onChange={(e) => {
              try {
                setTrailingPercentageVal(
                  new Decimal(e.target.value).mul(100).round().toString()
                );
                emitChange();
              } catch (ex) {
                //
              }
            }}
          >
            <FontAwesomeIcon icon={faPercentage} />
          </Input>
        </div>
      ) : null}

      {[StrategyType.TakeProfit].includes(selectedStrategyType) ? (
        <div className="space-y-2">
          <label className="font-medium" htmlFor="">
            Target Out
          </label>
          <Input
            placeholder="1.1"
            type="number"
            step="0.1"
            disabled={!editable}
            value={new Decimal(
              formatUnits(executionRateVal, tokenBDecimals)
            ).toString()}
            onChange={(e) => {
              try {
                setExecutionRateVal(
                  parseUnits(e.target.value, tokenBDecimals).toString()
                );
                emitChange();
              } catch (ex) {
                //
              }
            }}
          >
            <Token address={tokenB} />
          </Input>
        </div>
      ) : null}
    </div>
  );
};

export default StrategyForm;
