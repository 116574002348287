import { Button } from '@byont-ventures/react-dapp-framework';
import classNames from 'classnames';
import { useState } from 'react';
import {
  Strategy,
  StrategyStatus,
  useInvestmentContext,
} from '../../context/InvestmentContext';
import DeactivateStrategyModal from './Modals/DeactivateStrategyModal';
import StrategyForm from './StrategyForm';

export const StrategyCard: React.FunctionComponent<{
  strategy: Strategy;
}> = ({ strategy }) => {
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);

  const { selectedInvestment } = useInvestmentContext();

  return (
    <div className="p-4 space-y-4 bg-white border border-gray-200 rounded-lg">
      <DeactivateStrategyModal
        strategyId={strategy.id}
        isOpen={deactivateModalOpen}
        onClose={() => setDeactivateModalOpen(false)}
      />

      <div className="flex items-center justify-between pb-4 border-b border-gray-100">
        <div
          className={classNames('px-2 py-1 text-sm rounded-lg', {
            'bg-green-100 text-green-900':
              strategy.status === StrategyStatus.Executed,
            'bg-orange-100 text-orange-900':
              strategy.status === StrategyStatus.Activated,
          })}
        >
          Status: <span className="font-medium">{strategy.status}</span>
        </div>

        {StrategyStatus.Activated === strategy.status ? (
          <Button
            buttonStyle="danger"
            buttonSize="sm"
            onClick={() => setDeactivateModalOpen(true)}
          >
            Deactivate
          </Button>
        ) : null}
      </div>

      <StrategyForm
        strategyType={strategy.type}
        tokenAmount={strategy.amount}
        executionRate={strategy.executionRate}
        trailingPercentage={
          strategy.percentage ? String(strategy.percentage) : undefined
        }
        tokenA={selectedInvestment.tokenFrom}
        tokenB={selectedInvestment.tokenTo}
        editable={false}
      />
    </div>
  );
};

export default StrategyForm;
