import { Warden__factory } from './../generated/factories/Warden__factory';
import { Provider } from '@ethersproject/providers';
import { Signer } from 'ethers';
import { dappConfig } from '../config';
import {
  useConnection,
  useProvider,
} from '@byont-ventures/react-dapp-framework';

export const useWardenContract = (signerOrProvider?: Signer | Provider) => {
  const defaultProvider = useProvider().getSigner();
  const { desiredNetwork } = useConnection();

  return Warden__factory.connect(
    dappConfig.wardenContracts[desiredNetwork],
    signerOrProvider ?? defaultProvider
  );
};
