import {
  Button,
  Modal,
  NetworkSelector,
  Notification,
  Token,
  useDebounce,
  Input,
} from '@byont-ventures/react-dapp-framework';
import * as Sentry from '@sentry/nextjs';
import { faPercentage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useWardenContract } from '../../../hooks/useContract';
import { Decimal } from 'decimal.js';

export const CreateInvestmentModal: React.FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ children, ...props }) => {
  const [tokenFromValue, setTokenFromValue] = useState('');
  const [tokenToValue, setTokenToValue] = useState('');
  const [slippageValue, setSlippageValue] = useState('');
  const [loadingInvestment, setLoadingInvestment] = useState(false);
  const [investmentError, setInvestmentError] = useState<string | null>(null);

  const debouncedTokenFromValue = useDebounce(tokenFromValue, 300);
  const debouncedTokenToValue = useDebounce(tokenToValue, 300);

  const wardenContract = useWardenContract();

  const createInvestment = async () => {
    setLoadingInvestment(true);
    setInvestmentError(null);
    try {
      await wardenContract.activateInvestment(
        tokenFromValue,
        tokenToValue,
        new Decimal(slippageValue).mul(100).round().toFixed(),
        []
      );

      props.onClose();
    } catch (ex) {
      Sentry.captureException(ex);
      setInvestmentError(ex.message);
    } finally {
      setLoadingInvestment(false);
    }
  };

  return (
    <Modal {...props} title="Add Investment" className="w-full max-w-xl">
      <div className="flex flex-col space-y-4">
        <div className="w-full space-y-2">
          <label className="font-medium" htmlFor="">
            Select network
          </label>
          <div>
            <NetworkSelector />
          </div>
        </div>
        <div className="space-y-2">
          <label className="font-medium" htmlFor="">
            Token from
          </label>
          <Input
            placeholder="0x0000...0000"
            type="text"
            value={tokenFromValue}
            onChange={(e) => setTokenFromValue(e.target.value)}
          >
            <Token address={debouncedTokenFromValue} />
          </Input>
        </div>
        <div className="w-full space-y-2">
          <label className="font-medium" htmlFor="">
            Token to
          </label>
          <Input
            placeholder="0x0000...0000"
            type="text"
            value={tokenToValue}
            onChange={(e) => setTokenToValue(e.target.value)}
          >
            <Token address={debouncedTokenToValue} />
          </Input>
        </div>
        <div className="w-full space-y-2">
          <label className="font-medium" htmlFor="">
            Max Slippage
          </label>
          <Input
            placeholder="2"
            type="number"
            value={slippageValue}
            onChange={(e) => setSlippageValue(e.target.value)}
          >
            <FontAwesomeIcon icon={faPercentage} />
          </Input>
        </div>

        {investmentError !== null ? (
          <Notification notificationStyle="danger">
            <p className="max-w-full overflow-scroll">{investmentError}</p>
          </Notification>
        ) : null}

        <div className="flex justify-end space-x-4">
          <Button buttonStyle="info" onClick={props.onClose}>
            Dismiss
          </Button>
          <Button
            buttonStyle="primary"
            onClick={createInvestment}
            disabled={loadingInvestment}
            loading={loadingInvestment}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateInvestmentModal;
