import {
  Button,
  ERC20__factory,
  Token,
  useProvider,
} from '@byont-ventures/react-dapp-framework';
import { useInvestmentContext } from '../../context/InvestmentContext';
import {
  faArrowRight,
  faEmptySet,
  faPlus,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvestmentListItem from './InvestmentListItem';
import { useCallback, useEffect, useState } from 'react';
import CreateInvestmentModal from './Modals/CreateInvestmentModal';
import CreateStrategyModal from './Modals/CreateStrategyModal';
import DeactivateInvestmentModal from './Modals/DeactivateInvestmentModal';
import { StrategyCard } from './StrategyCard';

export const InvestmentsCard: React.FunctionComponent = () => {
  const provider = useProvider();
  const {
    strategiesLoading,
    investmentsLoading,
    investments,
    strategies,
    selectedInvestment,
  } = useInvestmentContext();

  const [loadingTokenSymbols, setLoadingTokenSymbols] = useState(false);
  const [tokenFromSymbol, setTokenFromSymbol] = useState<string | null>(null);
  const [tokenToSymbol, setTokenToSymbol] = useState<string | null>(null);

  const [investmentModalOpen, setInvestmentModalOpen] = useState(false);
  const [strategyModalOpen, setStrategyModalOpen] = useState(false);
  const [deactivateInvestmentModalOpen, setDeactivateInvestmentModalOpen] =
    useState(false);

  const getTokenSymbols = useCallback(async () => {
    if (null === selectedInvestment) return;

    setLoadingTokenSymbols(true);

    try {
      const [tokenFromSymbol, tokenToSymbol] = await Promise.all([
        ERC20__factory.connect(selectedInvestment.tokenFrom, provider).symbol(),
        ERC20__factory.connect(selectedInvestment.tokenTo, provider).symbol(),
      ]);

      setTokenFromSymbol(tokenFromSymbol);
      setTokenToSymbol(tokenToSymbol);
    } catch (ex) {
      setTokenFromSymbol('UNKN');
      setTokenToSymbol('UNKN');
    } finally {
      setLoadingTokenSymbols(false);
    }
  }, [selectedInvestment, provider]);

  useEffect(() => {
    getTokenSymbols();
  }, [getTokenSymbols, selectedInvestment]);

  return (
    <div className="flex bg-white border shadow-lg rounded-2xl border-100 max-h-[700px]">
      <CreateInvestmentModal
        isOpen={investmentModalOpen}
        onClose={() => setInvestmentModalOpen(false)}
      />

      <div className="flex flex-col h-full px-2 py-6 space-y-1">
        <div className="flex items-center px-4 mb-4 space-x-8">
          <h3 className="text-lg font-medium">Investments</h3>
          <Button
            onClick={() => setInvestmentModalOpen(true)}
            buttonStyle="primary"
            buttonSize="sm"
            className="space-x-2"
          >
            <FontAwesomeIcon icon={faPlus} />
            <span>Add investment</span>
          </Button>
        </div>

        {(() => {
          if (investmentsLoading) {
            return (
              <div className="flex items-center justify-center px-3 py-4 space-x-2 text-gray-500">
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  className="animate-spin"
                />
              </div>
            );
          } else if (investments.length === 0)
            return (
              <div className="flex items-center justify-center px-3 py-4 space-x-2 text-gray-500">
                <FontAwesomeIcon icon={faEmptySet} />
                <span>No results</span>
              </div>
            );
          else {
            return investments.map((investment, investmentIdx) => (
              <InvestmentListItem key={investmentIdx} investment={investment} />
            ));
          }
        })()}
      </div>

      {selectedInvestment && !loadingTokenSymbols ? (
        <div className="flex flex-col flex-1 border-l border-l-100">
          <CreateStrategyModal
            isOpen={strategyModalOpen}
            tokenA={selectedInvestment.tokenFrom}
            tokenB={selectedInvestment.tokenTo}
            onClose={() => setStrategyModalOpen(false)}
          />

          <DeactivateInvestmentModal
            isOpen={deactivateInvestmentModalOpen}
            onClose={() => setDeactivateInvestmentModalOpen(false)}
          />

          <div className="px-8 py-6 space-y-8 bg-gray-50 rounded-tr-2xl">
            <div className="flex items-center justify-between space-x-8">
              <h3 className="flex items-center text-lg font-medium">
                <div className="flex items-center space-x-4">
                  <div className="flex space-x-2">
                    <Token address={selectedInvestment.tokenFrom} />
                    <span className="font-medium">{tokenFromSymbol}</span>
                  </div>
                  <FontAwesomeIcon icon={faArrowRight} size="sm" />
                  <div className="flex space-x-2">
                    <Token address={selectedInvestment.tokenTo} />
                    <span className="font-medium">{tokenToSymbol}</span>
                  </div>
                  <span className="px-2 py-1 ml-2 text-sm font-normal text-gray-900 bg-gray-200 rounded-lg">
                    {selectedInvestment.slippage / 100}% Slippage
                  </span>
                </div>
              </h3>
              <Button
                buttonStyle="danger"
                buttonSize="sm"
                onClick={() => setDeactivateInvestmentModalOpen(true)}
              >
                Deactivate
              </Button>
            </div>
            <div className="sticky flex items-center justify-between space-x-8">
              <h3 className="text-lg font-medium">Strategies</h3>
              <Button
                buttonStyle="primary"
                buttonSize="sm"
                className="space-x-2"
                onClick={() => setStrategyModalOpen(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>Add strategy</span>
              </Button>
            </div>
          </div>
          <div className="h-full px-8 py-8 space-y-4 overflow-scroll border-t border-gray-200 bg-gray-50">
            {(() => {
              if (strategiesLoading) {
                return (
                  <div className="flex items-center justify-center px-3 py-4 space-x-2 text-gray-500">
                    <FontAwesomeIcon
                      icon={faSpinnerThird}
                      className="animate-spin"
                    />
                  </div>
                );
              } else if (strategies.length === 0)
                return (
                  <div className="flex items-center justify-center px-3 py-4 space-x-2 text-gray-500">
                    <FontAwesomeIcon icon={faEmptySet} />
                    <span>No results</span>
                  </div>
                );
              else {
                return strategies.map((strategy, strategyIdx) => (
                  <StrategyCard key={strategyIdx} strategy={strategy} />
                ));
              }
            })()}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InvestmentsCard;
