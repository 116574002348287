import Link from 'next/link';
import { AppFooter, AppHeader } from '@byont-ventures/react-dapp-framework';
import DCIPLogo from './Logo';

const PageLayout: React.FunctionComponent = ({ children }) => {
  return (
    <div className="flex flex-col max-h-screen min-h-screen">
      <AppHeader
        appLogo={
          <Link href="/" passHref>
            <a>
              <DCIPLogo className="fill-black" height={32} />
            </a>
          </Link>
        }
      />
      <div className="flex flex-col justify-between flex-1">
        <div className="container flex flex-col flex-1 py-12">{children}</div>
        <AppFooter />
      </div>
    </div>
  );
};

export default PageLayout;
