import {
  TokenPair,
  useERC20TokenContract,
} from '@byont-ventures/react-dapp-framework';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import {
  useInvestmentContext,
  Investment,
} from '../../context/InvestmentContext';
import classNames from 'classnames';

export const InvestmentListItem: React.FunctionComponent<{
  investment: Investment;
}> = ({ investment }) => {
  const { setSelectedInvestment, selectedInvestment } = useInvestmentContext();

  const tokenFromContract = useERC20TokenContract(investment.tokenFrom);
  const tokenToContract = useERC20TokenContract(investment.tokenTo);

  const [tokenFromSymbol, setTokenFromSymbol] = useState<string | null>(null);
  const [tokenToSymbol, setTokenToSymbol] = useState<string | null>(null);

  useEffect(() => {
    const getTokenSymbols = async () => {
      try {
        const [tokenFromResult, tokenToResult] = await Promise.all([
          tokenFromContract.symbol(),
          tokenToContract.symbol(),
        ]);
        setTokenFromSymbol(tokenFromResult);
        setTokenToSymbol(tokenToResult);
      } catch (ex) {
        setTokenFromSymbol('UNKN');
        setTokenToSymbol('UNKN');
      }
    };

    if (!tokenFromSymbol || !tokenToSymbol) getTokenSymbols();
  }, [tokenFromContract, tokenToContract, tokenFromSymbol, tokenToSymbol]);
  return (
    <button
      onClick={() => setSelectedInvestment(investment)}
      className={classNames(
        'px-4 py-3 transition-all rounded-lg hover:text-gray-900  group',
        selectedInvestment && selectedInvestment.id === investment.id
          ? 'bg-gray-100'
          : 'bg-white hover:bg-gray-100'
      )}
    >
      {(() =>
        tokenFromSymbol && tokenToSymbol ? (
          <div className="grid grid-cols-12">
            <div className="flex col-span-7 space-x-2">
              <div className="relative">
                <TokenPair
                  tokenAddressA={investment.tokenFrom}
                  tokenAddressB={investment.tokenTo}
                />
              </div>
              <p>
                {tokenFromSymbol}/{tokenToSymbol}
              </p>
            </div>
            <div className="flex items-center col-span-4">
              <p className="px-2 text-sm text-gray-900 transition-all bg-gray-100 rounded-full group-hover:bg-gray-200">
                {investment.slippage / 100}% Slippage
              </p>
            </div>

            <div className="flex items-center justify-end col-span-1">
              <FontAwesomeIcon icon={faChevronRight} size="sm" />
            </div>
          </div>
        ) : null)()}
    </button>
  );
};

export default InvestmentListItem;
