import {
  Button,
  Modal,
  Notification,
  useConnection,
  useERC20TokenContract,
  useProvider,
} from '@byont-ventures/react-dapp-framework';
import * as Sentry from '@sentry/nextjs';
import { useWardenContract } from '../../../hooks/useContract';
import { useInvestmentContext } from '../../../context/InvestmentContext';
import StrategyForm from '../StrategyForm';
import { useState } from 'react';
import type { Warden } from '../../../generated/Warden';
import { dappConfig } from '../../../config';

export const CreateStrategyModal: React.FunctionComponent<{
  isOpen: boolean;
  tokenA: string;
  tokenB: string;
  onClose: () => void;
}> = ({ children, tokenA, tokenB, ...props }) => {
  const [loadingStrategy, setLoadingStrategy] = useState(false);
  const [strategyError, setStrategyError] = useState<string | null>(null);
  const [strategyParams, setStrategyParams] = useState(
    {} as Warden.StrategyInputDataStruct & { token: string }
  );

  const wardenContract = useWardenContract();
  const provider = useProvider();
  const tokenAContract = useERC20TokenContract(tokenA, provider.getSigner());
  const { desiredNetwork } = useConnection();

  const { selectedInvestment } = useInvestmentContext();

  const createStrategy = async () => {
    setLoadingStrategy(true);
    setStrategyError(null);

    try {
      const userAddress = await provider.getSigner().getAddress();

      // TODO
      const tokenContract =
        strategyParams.token === tokenA ? tokenAContract : tokenAContract;

      const existingAllowance = await tokenContract.allowance(
        userAddress,
        dappConfig.wardenContracts[desiredNetwork]
      );

      const tx = await tokenContract.approve(
        dappConfig.wardenContracts[desiredNetwork],
        existingAllowance.add(strategyParams.tokenAmount)
      );

      await tx.wait();

      await wardenContract.activateStrategies(
        [strategyParams],
        selectedInvestment.id
      );
      props.onClose();
    } catch (ex) {
      Sentry.captureException(ex);
      setStrategyError(ex.message);
    } finally {
      setLoadingStrategy(false);
    }
  };

  return (
    <Modal {...props} title="Add Strategy" className="w-80">
      <StrategyForm
        tokenA={tokenA}
        tokenB={tokenB}
        onChange={(data) => setStrategyParams(data)}
      />

      {strategyError !== null ? (
        <Notification notificationStyle="danger">
          <p className="max-w-full overflow-scroll">{strategyError}</p>
        </Notification>
      ) : null}

      <div className="flex justify-end pt-2 space-x-4">
        <Button buttonStyle="info" onClick={props.onClose}>
          Dismiss
        </Button>
        <Button
          buttonStyle="primary"
          onClick={createStrategy}
          disabled={loadingStrategy}
          loading={loadingStrategy}
        >
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default CreateStrategyModal;
