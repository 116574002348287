/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers';
import { Provider } from '@ethersproject/providers';
import type { Warden, WardenInterface } from '../Warden';

const _abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_admin',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_investor',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_controller',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_router',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bool',
        name: 'active',
        type: 'bool',
      },
    ],
    name: 'ActiveStateChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'investor',
            type: 'address',
          },
          {
            internalType: 'contract IERC20',
            name: 'tokenA',
            type: 'address',
          },
          {
            internalType: 'contract IERC20',
            name: 'tokenB',
            type: 'address',
          },
          {
            internalType: 'uint32',
            name: 'slippage',
            type: 'uint32',
          },
          {
            internalType: 'bool',
            name: 'archived',
            type: 'bool',
          },
        ],
        indexed: false,
        internalType: 'struct Warden.Investment',
        name: 'investment',
        type: 'tuple',
      },
    ],
    name: 'InvestmentActivated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'investor',
            type: 'address',
          },
          {
            internalType: 'contract IERC20',
            name: 'tokenA',
            type: 'address',
          },
          {
            internalType: 'contract IERC20',
            name: 'tokenB',
            type: 'address',
          },
          {
            internalType: 'uint32',
            name: 'slippage',
            type: 'uint32',
          },
          {
            internalType: 'bool',
            name: 'archived',
            type: 'bool',
          },
        ],
        indexed: false,
        internalType: 'struct Warden.Investment',
        name: 'investment',
        type: 'tuple',
      },
    ],
    name: 'InvestmentArchived',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'previousAdminRole',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'newAdminRole',
        type: 'bytes32',
      },
    ],
    name: 'RoleAdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'RoleGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'RoleRevoked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'investmentId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startingRate',
            type: 'uint256',
          },
          {
            internalType: 'uint32',
            name: 'percentage',
            type: 'uint32',
          },
          {
            internalType: 'uint256',
            name: 'executionRate',
            type: 'uint256',
          },
          {
            internalType: 'enum Warden.StrategyType',
            name: 'strategyType',
            type: 'uint8',
          },
          {
            internalType: 'enum Warden.Status',
            name: 'status',
            type: 'uint8',
          },
        ],
        indexed: false,
        internalType: 'struct Warden.StrategyData',
        name: 'strategy',
        type: 'tuple',
      },
    ],
    name: 'StrategyActivated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'investmentId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startingRate',
            type: 'uint256',
          },
          {
            internalType: 'uint32',
            name: 'percentage',
            type: 'uint32',
          },
          {
            internalType: 'uint256',
            name: 'executionRate',
            type: 'uint256',
          },
          {
            internalType: 'enum Warden.StrategyType',
            name: 'strategyType',
            type: 'uint8',
          },
          {
            internalType: 'enum Warden.Status',
            name: 'status',
            type: 'uint8',
          },
        ],
        indexed: false,
        internalType: 'struct Warden.StrategyData',
        name: 'strategy',
        type: 'tuple',
      },
    ],
    name: 'StrategyDeactivated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'stategyId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'srcReceiver',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'dstReceiver',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'contract IERC20',
        name: 'srcToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'contract IERC20',
        name: 'destToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'srcAmount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'dstAmount',
        type: 'uint256',
      },
    ],
    name: 'SwapExecuted',
    type: 'event',
  },
  {
    inputs: [],
    name: 'ADMIN_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'CONTROLLER_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'INVESTOR_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20',
        name: 'tokenA',
        type: 'address',
      },
      {
        internalType: 'contract IERC20',
        name: 'tokenB',
        type: 'address',
      },
      {
        internalType: 'uint32',
        name: 'slippage',
        type: 'uint32',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'tokenAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startingRate',
            type: 'uint256',
          },
          {
            internalType: 'uint32',
            name: 'percentage',
            type: 'uint32',
          },
          {
            internalType: 'uint256',
            name: 'executionRate',
            type: 'uint256',
          },
          {
            internalType: 'enum Warden.StrategyType',
            name: 'strategyType',
            type: 'uint8',
          },
        ],
        internalType: 'struct Warden.StrategyInputData[]',
        name: 'strategies',
        type: 'tuple[]',
      },
    ],
    name: 'activateInvestment',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'tokenAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startingRate',
            type: 'uint256',
          },
          {
            internalType: 'uint32',
            name: 'percentage',
            type: 'uint32',
          },
          {
            internalType: 'uint256',
            name: 'executionRate',
            type: 'uint256',
          },
          {
            internalType: 'enum Warden.StrategyType',
            name: 'strategyType',
            type: 'uint8',
          },
        ],
        internalType: 'struct Warden.StrategyInputData[]',
        name: 'strategies',
        type: 'tuple[]',
      },
      {
        internalType: 'uint256',
        name: 'investmentId',
        type: 'uint256',
      },
    ],
    name: 'activateStrategies',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'admin',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'contractActive',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'controller',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'investmentId',
        type: 'uint256',
      },
    ],
    name: 'deactivateInvestment',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'strategyId',
        type: 'uint256',
      },
    ],
    name: 'deactivateStrategy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
    ],
    name: 'getRoleAdmin',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'hasRole',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'investments',
    outputs: [
      {
        internalType: 'address',
        name: 'investor',
        type: 'address',
      },
      {
        internalType: 'contract IERC20',
        name: 'tokenA',
        type: 'address',
      },
      {
        internalType: 'contract IERC20',
        name: 'tokenB',
        type: 'address',
      },
      {
        internalType: 'uint32',
        name: 'slippage',
        type: 'uint32',
      },
      {
        internalType: 'bool',
        name: 'archived',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'investor',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'investorIsAllowed',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'router',
    outputs: [
      {
        internalType: 'contract oneInchRouter',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'routerAddress',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bool',
        name: 'active',
        type: 'bool',
      },
    ],
    name: 'setContractActiveState',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_address',
        type: 'address',
      },
    ],
    name: 'setRouter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId',
        type: 'bytes4',
      },
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'strategyId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'minOut',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: '_data',
        type: 'bytes',
      },
    ],
    name: 'swap',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export class Warden__factory {
  static readonly abi = _abi;
  static createInterface(): WardenInterface {
    return new utils.Interface(_abi) as WardenInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Warden {
    return new Contract(address, _abi, signerOrProvider) as Warden;
  }
}
