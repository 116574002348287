import React from 'react';

export const Logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => (
  <svg
    {...props}
    viewBox="0 0 36 36"
    fill="currentFill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.4993 9.00147L27.0015 4.50074L22.5007 0L18 4.50074L22.5007 9.00147L18 13.4993L22.5007 18L27.0015 13.4993L31.4993 18L36 13.4993L31.4993 9.00147Z"
      fill="currentFill"
    />
    <path
      d="M27.0015 31.4993L31.4993 27.0015L36 22.5007L31.4993 18L27.0015 22.5007L22.5007 18L18 22.5007L22.5007 27.0015L18 31.4993L22.5007 36L27.0015 31.4993Z"
      fill="currentFill"
    />
    <path
      d="M4.50074 27.0015L9.00147 31.4993L13.4993 36L18 31.4993L13.4993 27.0015L18 22.5007L13.4993 18L9.00147 22.5007L4.50074 18L0 22.5007L4.50074 27.0015Z"
      fill="currentFill"
    />
    <path
      d="M9.00147 4.50074L4.50074 9.00147L0 13.4993L4.50074 18L9.00147 13.4993L13.4993 18L18 13.4993L13.4993 9.00147L18 4.50074L13.4993 0L9.00147 4.50074Z"
      fill="currentFill"
    />
  </svg>
);

export default Logo;
