import {
  Button,
  Modal,
  Notification,
} from '@byont-ventures/react-dapp-framework';
import * as Sentry from '@sentry/nextjs';
import { useState } from 'react';
import { useWardenContract } from '../../../hooks/useContract';
import { useTranslation } from 'react-i18next';

export const DeactivateStrategyModal: React.FunctionComponent<{
  isOpen: boolean;
  strategyId: string;
  onClose: () => void;
}> = ({ children, strategyId, ...props }) => {
  const { t } = useTranslation('react-dapp-framework');

  const [currentError, setCurrentError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const wardenContract = useWardenContract();

  const deactivateStrategy = async () => {
    setLoading(true);
    setCurrentError(null);
    try {
      await wardenContract.deactivateStrategy(strategyId);
      props.onClose();
    } catch (ex) {
      Sentry.captureException(ex);
      setCurrentError(ex.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title={t('deactivate-strategy')} className="max-w-lg" {...props}>
      <div className="pb-4 text-gray-500">
        {t('are-you-sure-deactivate-investment')}
      </div>

      {currentError !== null ? (
        <Notification notificationStyle="danger">{currentError}</Notification>
      ) : null}

      <div className="flex justify-end">
        <div className="flex space-x-4">
          <Button buttonStyle="info" onClick={() => props.onClose()}>
            {t('dismiss')}
          </Button>
          <Button
            buttonStyle="danger"
            loading={loading}
            disabled={loading}
            onClick={() => deactivateStrategy()}
          >
            {t('confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeactivateStrategyModal;
