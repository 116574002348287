import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';

import i18nConfig from '../next-i18next.config';
import InvestmentsCard from '../components/Investments/InvestmentsCard';
import PageLayout from '../components/Shared/PageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

const Index: React.FunctionComponent = () => {
  return (
    <PageLayout>
      <InvestmentsCard />

      <a
        href="https://byont.notion.site/Automatic-Investments-Documentation-833df7e292ee4f52b60dbb3ce029e70b"
        target="_blank"
        rel="noreferrer noopener"
        className="mt-4 space-x-2 text-sm text-primary-600 hover:underline hover:text-primary-900"
      >
        <FontAwesomeIcon icon={faExternalLink} />
        <span>View explanation on Notion</span>
      </a>
    </PageLayout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], i18nConfig)),
    },
  };
};

export default Index;
